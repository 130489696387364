<template>

    <div class="yj-conten" style="background-color:#fff;width:calc(100% - 32px);min-width:fit-content">
        <a-layout-content style="padding:0;">
            <div class="yj-conten " style="background: #fff;padding: 20px 20px 0;margin: 0;">
       

                    <a-form-model-item  :label="item.Rule.HonorName" v-for="item in tableData" :key="item.Rule.ID" v-show="item.Rule.HonorLevel>1">
                        <a-form-model layout='inline'>


                            <a-form-model-item :label="itemIndex.IndexName" prop="ReadTarget" v-for="itemIndex in item.RuleIndex" :key="itemIndex.ID">
                                <a-input-number :min="0" placeholder="" style="width:70px" v-model="itemIndex.LimitCount" />
                            </a-form-model-item>
                            <br />
                            <a-form-model-item label="奖励金额" prop="ReportTarget">
                                <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.Cash" />
                            </a-form-model-item>
                            <!--<a-form-model-item label="新用户数量" prop="NewUserTarget">
        <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.ID"/>
    </a-form-model-item>
    <a-form-model-item label="完善手机人数" prop="WantUserTarget">
        <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.ID"/>
    </a-form-model-item>
    <a-form-model-item label="订单数量" prop="ReportTarget">
        <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.ID"/>
    </a-form-model-item>
    <a-form-model-item label="粉丝人数" prop="ReportTarget">
        <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.ID"/>
    </a-form-model-item>
    <br/>
    <a-form-model-item label="奖励金额" prop="ReportTarget">
        <a-input-number :min="0" placeholder="" style="width:70px" v-model="item.Rule.ID"/>
    </a-form-model-item>-->
                        </a-form-model>
                    </a-form-model-item>

             

                <a-form-model-item label="" class="textal_c">
                    <div v-if="this.$route.query.rowType !='select'">
                        <a-button type="primary" @click="save();">
                            保存
                        </a-button>
                    </div>
                </a-form-model-item>

            </div>
        </a-layout-content>
    </div>
</template>
<script>
    import http from "@/Plugin/Http.js";
   // import util from "@/Plugin/util.js";
    export default {
        name: "M2Activity_List",
        data() {
            return {   
                tableData:null,
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 2 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 22 },
                    },
                },
            };
        },
        props: {//组件属性
        },
        methods: {//方法
           
            getTableData: function () {
                var self = this;
                var op = {
                    url: "/PartnerModule/PartnerHonor/GetPartnerHonorList",
                    data: {
                    
                    },
                    OnSuccess: function (data) {
                       // console.log(data);                      
                        self.tableData = data.data;
                        //console.log(self.tableData);
                        //self.pagination.total = data.data.Total;
                        //self.pagination.defaultPageSize = data.data.RowCount;
                        //self.pagination.current = data.data.PageIndex;
                    }
                };
                http.Post(op);
            },
            save: function () {
                var self = this;
               
                //console.log(self.tableData);
                var isConfig = false;

                for (var i = 0; i < self.tableData.length;i++)
                {
       
                    console.log(self.tableData[i].Rule.HonorLevel);
                    if (self.tableData[i].Rule.HonorLevel == 1)
                        continue;

                    for (var j = 0; j < self.tableData[i].RuleIndex.length; j++)
                    {
                        if (self.tableData[i].RuleIndex[j].LimitCount != 0) {
                            isConfig = true;
                            break;
                        }
                    }
                   
                    if (!isConfig) {
                        alert("请配置" + self.tableData[i].Rule.HonorName+"等级规则！");
                        return;
                    }
                    isConfig = false;

                   // console.log(self.tableData[i]);

                }
               

                var op = {
                    url: "/PartnerModule/PartnerHonor/SavePartnerHonor",
                    data: {
                        result: self.tableData
                    },
                    OnSuccess: function (data) {




                        console.log(data);
                        self.$message.success('保存成功', 1.5, function () { });
                        self.$router.push({
                            name: "PartnerHonor_List"
                        });
                        //self.tableData = data.data;
                        //console.log(self.tableData);             
                    }
                };
                http.Post(op);

            }
        
        },

        computed: {//计算属性
        },
        watch: {//侦听属性
        },     
        mounted: function () {//生命周期，组装完成
            this.getTableData();
        }
      
    };
</script>